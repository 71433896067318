<template>
  <main>
    <div class="card">
      <div
        class="
          card-header
          py-2
          d-flex
          justify-content-between
          align-items-center
        "
      >
        <input
          type="text"
          v-model="seccion_formulario.nombre"
          class="form-control form-control-sm form-control-plaintext w-25"
          placeholder="Nombre de la sección"
        />
        <div>
          <button class="btn btn-light text-primary btn-xs m-0" @click="nuevaPregunta">
            <i class="fa fa-plus fa-fw"></i>
            Nueva pregunta
          </button>
          <slot name="action"></slot>
        </div>
      </div>
      <div class="card-body">
        <div
          class="row"
          v-for="(pregunta, index) in seccion_formulario.preguntas"
          :key="`pregunta_${index}`"
        >
          <div class="col-lg-11 col-md-11 col-sm-10 col-xs-10">
          
            <pregunta
              class="mb-2"
              v-model="seccion_formulario.preguntas[index]"
            ></pregunta>
          </div>
          <div class="col-lg-1 col-md-1 col-sm-2 col-xs-2">
            <button class="btn btn-sm btn-light text-danger" @click="borrarPregunta(index)">
              <i class="fa fa-times fa-fw"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import Pregunta from "./pregunta.vue";

export default {
  components: {
    Pregunta,
  },
  props: ["value"],
  data() {
    return {
      seccion: this.value,
      seccion_formulario: {
        nombre: "",
        preguntas: [],
      },
    };
  },
  created() {
    this.seccion_formulario = this.value;
  },
  methods: {
    nuevaPregunta() {
      this.seccion_formulario.preguntas.push({
        nombre: "",
        tipo: "TEXTO",
        opciones : []
      });
    },
    borrarPregunta(index){
        this.seccion_formulario.preguntas.splice(index, 1);
    }
  },
  watch: {
    value: {
      deep: true,
      handler: function () {
        this.seccion_formulario = this.value;
      },
    },
    seccion_formulario: {
      deep: true,
      handler: function () {
        this.seccion = this.seccion_formulario;
        this.$emit("input", this.seccion);
      },
    },
  },
};
</script>
