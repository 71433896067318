<template>
  <main>
    <header
      class="
        page-header page-header-compact page-header-light
        border-bottom
        bg-white
        mb-4
      "
    >
      <div class="container-xl px-4">
        <div class="page-header-content">
          <div class="row align-items-center justify-content-between pt-3">
            <div class="col-auto mb-3">
              <h1 class="page-header-title">
                <div class="page-header-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-file"
                  >
                    <path
                      d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"
                    ></path>
                    <polyline points="13 2 13 9 20 9"></polyline>
                  </svg>
                </div>
                {{ formulario.nombre }}
              </h1>
            </div>
            <div class="col-12 col-xl-auto mb-3">
              <button
                class="btn btn-sm btn-light text-primary"
                @click="nuevo()"
              >
                <i class="fa fa-plus fa-fw"></i> Nueva Sección
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="container">
      <div
        class="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12 col-xs-12"
        v-for="(seccion, index) in secciones"
        :key="`seccion_${index}`"
      >
        <seccion-formulario class="mb-2" v-model="secciones[index]">
          <template #action>
            <button
              class="btn btn-sm btn-light text-danger"
              @click="borrarSeccion(index)"
            >
              <i class="fa fa-trash-alt fa-fw"></i>
            </button>
          </template>
        </seccion-formulario>
        
      </div>
<button class="btn btn-success" @click="save();" type="button">Guardar</button>
    </div>
  </main>
</template>
<script>
import formulariosService from "../../../../../services/formulariosService";
import SeccionFormulario from "./create.vue";
import Toast from '../../../../../components/common/utilities/toast';
import seccionFormulariosService from "../../../../../services/seccionFormulariosService";

export default {
  components: { SeccionFormulario },
  data() {
    return {
      formulario: {},
      id_formulario:'',
      secciones: [],
    };
  },
  methods: {
    async save(){
        try {
            this.LoaderSpinnerShow();

            let forms={
                id_formulario: this.id_formulario,
                secciones:this.secciones
            }

            await seccionFormulariosService.store(forms);

            this.LoaderSpinnerHide();

            Toast.fire({
                icon: 'success',
                title: 'Datos guardados con exito'
            });

        } catch (error) {
            this.LoaderSpinnerHide();
            Toast.fire({
                icon: 'error',
                title: 'Ocurrio un error al procesar la solicitud'
            });
        }
    },
    async getFormulario(id) {
      const response = await formulariosService.show(id);
      this.formulario = response.data;
    },
    nuevo() {
      this.secciones.push({
        nombre: "Nueva seccion",
        preguntas: [],
      });
    },
        borrarSeccion(index){
        this.secciones.splice(index, 1);
    }
  },
  created() {
      this.id_formulario=this.$route.params.id;
    this.getFormulario(this.$route.params.id);
  },
};
</script>