<template>
    <main>
        <div class="row mt-2" v-for="(opcion, index) in opciones" :key="`${index}_opcion`">
            <div class="col-6 d-flex justify-content-start">
                <input type="text"  v-model="opciones[index]" class="form-control form-control-sm form-control-plaintext w-75" placeholder="Opcion">
                <button class="btn btn-sm btn-light text-danger" @click="borrarOpciones(index)">
                    <i class="fa fa-times fa-fw"></i>
                </button>
            </div>
        </div>
    </main>
</template>
<script>

export default {
    props : ['value'],
    data (){
        return {
            opciones : this.value,
            opciones_pregunta : []
        } 
    },
    created(){
        this.opciones_pregunta = this.value;
    },
    methods: {
        borrarOpciones(index){
            this.opciones_pregunta.splice(index,1);
        }
    },
    watch: {
        value : {
            deep : true,
            handler : function(){
                this.opciones_pregunta = this.value; 
            }
        },
        opciones_pregunta : {
            deep: true,
            handler : function(){
                this.opciones = this.opciones_pregunta;
                this.$emit('input', this.opciones);
            }
        }
    }
}
</script>
