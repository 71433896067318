<template>
  <main>
    <div class="row">
      <div class="col-6">
        <input
          type="text"
          v-model="pregunta_seccion.nombre"
          class="form-control form-control-sm form-control-plaintext"
          placeholder="Escriba aqui la pregunta"
        />
      </div>
      <div class="col-6 d-flex justify-content-between">
        <select
          v-model="pregunta_seccion.tipo"
          class="form-control form-control-sm"
          @change="onChangeQuestionType"
        >
          <option
            v-for="(option, index) in options"
            :key="`option_${index}`"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
        <button v-if="pregunta_seccion.tipo === 'OPCIONES'" class="btn btn-xs btn-datatable btn-light text-primary ml-1" @click="addOption">
          <i class="fa fa-plus fa-fw"></i>
        </button>
      </div>
    </div>
    <div class="row pr-2 mt-2" v-if="pregunta_seccion.tipo === 'OPCIONES'">
      <div class="col-12">
        <opciones v-model="pregunta_seccion.opciones"></opciones>
      </div>
    </div>
  </main>
</template>
<script>
import Opciones from "./options";

export default {
  components: {
    Opciones,
  },
  props: ["value"],
  data() {
    return {
      pregunta: this.value,
      pregunta_seccion: {
        nombre: "",
        tipo: "TEXTO",
        opciones: [],
      },
      options: ["TEXTO", "OPCIONES"],
    };
  },
  created() {
    this.pregunta_seccion = this.value;
  },
  watch: {
    value: {
      deep: true,
      handler: function () {
        this.pregunta_seccion = this.value;
      },
    },
    pregunta_seccion: {
      deep: true,
      handler: function () {
        this.pregunta = this.pregunta_seccion;
        this.$emit("input", this.pregunta);
      },
    },
  },
  methods: {
    onChangeQuestionType() {
      if (this.pregunta_seccion.tipo === "OPCIONES") {
        this.pregunta_seccion.opciones.push("Opcion 1");
      } else {
        this.pregunta_seccion.opciones = [];
      }
    },
    addOption(){
        this.pregunta_seccion.opciones.push(`Opcion ${this.pregunta_seccion.opciones.length + 1}`);
    }
  },
};
</script>
